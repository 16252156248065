.my-header-TESTING {
  top: 0;
  position: sticky;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid #898a8d;
  align-items: center;
  height: 50px;
  padding: 10px;
  width: 100%;
  background-color: #19333f;
}

.my-header-STAGING {
  top: 0;
  position: sticky;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid #1890ff;
  align-items: center;
  height: 50px;
  padding: 10px;
  width: 100%;
  background-color: #19333f;
}
.my-header-PROD {
  top: 0;
  position: sticky;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid #ed2727;
  align-items: center;
  height: 50px;
  padding: 10px;
  width: 100%;
  background-color: #19333f;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}

.title {
  display: flex;
  align-items: center;
  color: #fff;
  margin-left: 15px;
  font-size: 1.5rem;
}

.logo-image {
  height: auto;
  width: 125px;
}

.profile {
  display: flex;
  align-items: center;
  height: inherit;
}

.username {
  color: #fff;
  margin-left: 8px;
}

.dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.ant-dropdown-link {
  color: white;
}

.ant-layout-header {
  line-height: 30px;
}

.ant-layout-sider-children {
  margin-top: -50px;
}

.env-TESTING,
.env-STAGING,
.env-PROD {
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  color: #fff;
  letter-spacing: 0.5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  margin: 0px 5px;
}

.env-TESTING {
  background-color: #898a8d;
}
.env-STAGING {
  background-color: #1890ff;
}
.env-PROD {
  background-color: #ed2727;
}
