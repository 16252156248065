.main-container {
  margin: 10px;
  margin-top: 10px;
}

.bredcrumb-container {
  display: flex;
  justify-content: space-between;
}

.header-icon {
  height: 30px;
  width: 30px;
}

.site-page-header {
  background-color: white;
  margin-top: 10px;
}

.header-title {
  margin-left: 0.5rem;
}

.button-container {
  margin-right: 8px;
}

.edit-button,
.save-button {
  margin: 0px 5px;
  background-color: blue;
  color: white;
}
.collapse-item {
  margin: 10px 0px;
}

.ant-checkbox {
  color: rgba(0, 0, 0, 0.85);
}
