.main-container {
  margin: 10px;
}

.search-row {
  display: flex;
}

.search-child-row {
  display: flex;
  justify-content: space-between;
}

.ant-space.ant-space-vertical {
  width: 100%;
}

.search-column {
  margin-left: 1rem;
  margin-right: 1rem;
}

.header-icon {
  height: 30px;
  width: 30px;
}

.site-page-header {
  background-color: white;
  margin-top: 10px;
}

.header-title {
  margin-left: 0.5rem;
}

.plus-icon {
  font-size: 30px;
  color: grey;
}

.primary-btn {
  background-color: blue !important;
  margin-bottom: 10px;
}

.disabled-btn {
  background-color: #b9b9b9 !important;
  margin-bottom: 10px;
}

.white-text {
  color: white;
}

.table-header {
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.center-div {
  display: flex;
  align-items: center;
}

.approx-img {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.loading-style {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.ant-card.search-box {
  margin-top: 34px;
}
.ant-input.bg_red::placeholder {
  color: white;
}
.custom-row-deleted {
  background-color: #d9d9d9;
}

table {
  transform: rotateX(180deg);
}

.ant-table-content {
  transform: rotateX(180deg);
}
