.custom-select .ant-select-selector {
  border: 1px solid black;
}

.col-border {
  border: 1px solid #e2e2e2;
  padding: 15px;
}
.sub-heading {
  margin: 5px;
}

.col-config-item {
  width: 32%;
  float: left;
  height: 53px;
  margin: 5px;
}

.col-config-item-inner {
  display: flex;
  border: 1px solid grey;
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  height: 100%;
  cursor: grab;
  font-size: 20px;
  user-select: none;
}

.dragged {
  min-width: 70px !important;
  z-index: 999999999999;
}

.dragged .col-config-item-inner {
  box-shadow: 5px 5px 8px grey;
}

.specific-box .ant-select.ant-select-in-form-item {
  width: 100%;
  margin-left: -2%;
}
